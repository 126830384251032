.footer {
    padding: 1rem 1.5rem 0rem;
}

.gridjs-search {
    float: right;
}

#details-commande-section .tabs.is-toggle li.is-active a {
    background-color: #3e8ed0;
    border-color: #3e8ed0;
    color: #fff;
    z-index: 1;
}

#details-commande-section .container {
    border: 1px solid #dbdbdb;
    border-radius: 5px;
}

